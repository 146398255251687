import { Component } from '@angular/core';
import {NavigationStart, Router, RouterOutlet} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "./services/notification.service";

import {AuthUserService} from "./services/auth-user.service";

import {AuthModule, AuthService} from "@makeo-packages/mkongusers";
import {filter, takeUntil} from "rxjs";
import {DestroyerService} from "@makeo-packages/mkongtools";
import {AlertService} from "./services/alert.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, AuthModule],
})
export class AppComponent {

    public isLoad =false;

    /**
     * Constructor
     */
    constructor(

      protected authService: AuthService,
      private notificationService: NotificationService,
      private authUserService: AuthUserService,
      private destroyService: DestroyerService,
      private router: Router,
      private alertService: AlertService,
    ) {
      // router.events.pipe(
      //   filter(event => event instanceof NavigationStart),
      // ).subscribe(_ => {
      //   debugger;
      // });
    }
}
  // ngOnInit() {
  //   if (this.router.url !== "/connexion") {
  //     //console.log("this.router.url : " + this.router.url);
  //
  //     // this.authService.check().pipe(takeUntil(this.destroyService._onDestroy)).subscribe({
  //     //   next: (res) => {
  //     //     if (res) {
  //     //       this.authUserService.getUserInfos()
  //     //         .pipe(takeUntil(this.destroyService._onDestroy))
  //     //         .subscribe({
  //     //           next: data => {
  //     //             if (data) {
  //     //               this.authUserService.userSubject.next(data.datas);
  //     //               this.isLoad = true
  //     //             } else {
  //     //               this.router.navigate(['/connexion']).then(x => x);
  //     //               this.isLoad = true
  //     //
  //     //             }
  //     //           }
  //     //           , error: (error: any) => {
  //     //             //this.alertCenterService.error(this.translateService.instant(error.error.message), true);
  //     //             this.handleHttpError(error);
  //     //           }
  //     //         })
  //     //     } else {
  //     //       this.router.navigate(['/connexion']).then(x => x);
  //     //       this.isLoad = true
  //     //
  //     //     }
  //     //   }
  //     // })
  //     // this.authService.signInEvent.pipe(takeUntil(this.destroyService._onDestroy)).subscribe({
  //     //   next: () => {
  //     //     this.checkCurrentUser();
  //     //     this.isLoad = true
  //     //
  //     //   }
  //     // })
  //   }
  // }
  //
  // private checkCurrentUser(redirectToLogin = false) {
  //   this.authUserService.getUserInfos()
  //     .pipe(takeUntil(this.destroyService._onDestroy))
  //     .subscribe({
  //       next: data => {
  //         if (data) {
  //           this.authUserService.userSubject.next(data.datas);
  //           this.router.navigate(['/dashboard']).then(x => x);
  //           this.isLoad = true
  //
  //         } else if (redirectToLogin) {
  //           this.router.navigate(['/connexion']).then(x => x);
  //           this.isLoad = true
  //
  //         }
  //       },
  //       error: (error: any) => {
  //         //this.alertCenterService.error(this.translateService.instant(error.error.message), true);
  //         this.handleHttpError(error);
  //       }
  //     })
  // }
  //
  //
  // private handleHttpError( error : any){
  //   let errorMessage = "";
  //   if (error.error && error.error.message) {
  //     errorMessage = error.error.message;
  //   }
  //   if(errorMessage !== ""){
  //     this.alertService.error(errorMessage);
  //     this.isLoad = true
  //   }else{
  //     console.log("handleHttpError skip empty error msg", error);
  //   }
  // }
