//https://gitlab.staging.makeo.fr/makeo-packages/mkongusers/-/blob/develop/projects/mkongusers/src/lib/interceptors/auth.interceptor.ts

import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, finalize, Observable, ReplaySubject, switchMap, throwError} from 'rxjs';

import {Router} from "@angular/router";
import {AuthService} from "@makeo-packages/mkongusers";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private nonAuthRoutes = [
    /^.*\/login_check$/g
  ];
  private nonAuthFrontRoute = [
    /^.*\/connexion$/g,
    /^.*\/inscription#.*$/g
  ]

  tokenSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  private isRefreshingToken: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    // /!\ erreur EmptyErrorImpl si on essaye de faire passer dans le contructeur :
    //   private authService: AuthService,   avec    import {AuthService} from "../services/auth.service"
    //   ou
    //   private authService: AuthCustomService,    avec class AuthCustomService extends AuthService de @makeo-packages/mkongusers
    // ... authService doit etre celui de from "@makeo-packages/mkongusers"
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any> = this.createRequestWithToken(request);
    return next.handle(newReq).pipe(
      catchError((err: HttpErrorResponse) => {
        let message = this.extractErrorMessage(err);

        if(message.includes("Jeton authentification non 2FA_OK"))
        {
          this.logout();
        }

        throw err;
      })
    );
  }

  private logout()
  {
    setTimeout(() => {
      this.router.navigate(['connexion'])
      this.authService.signOut()
    }, 1000);
  }

  private extractErrorMessage(error: HttpErrorResponse) : string {
    let message = error.error.message;

    return message;
  }

  private createRequestWithToken(request: HttpRequest<any>) {
    if (this.authService.accessToken != null) {
      return  request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.accessToken)
      });
    } else {
      return  request.clone();
    }
  }

  /**
   * Intercept
   *
   * @param request
   * @param next
   */
  _intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let newReq: HttpRequest<any>;
    console.log(request.headers.get("Authorization"))
    if (this.authService.accessToken != null) {
      newReq = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.accessToken)
      });
    } else {
      newReq = request.clone();
    }
    //let newReq = request.clone({setHeaders: {Authorization: 'Bearer ' + this.authService.accessToken}});
    //console.log('[auth.interceptor] intercepted request, add header', request); // OK
    return next.handle(newReq).pipe(catchError(err => {
        console.log(err);
        let regExps = this.nonAuthFrontRoute.filter(a => a.test(this.router.url));

        if (err.error?.datas?.includes("Jeton authentification non 2FA_OK") || err.error?.message?.includes("Jeton authentification non 2FA_OK")) {
          setTimeout(() => {
            this.router.navigate(['connexion'])
            this.authService.signOut()
          }, 1000);
        }

        if ([401, 403].indexOf(err.status) !== -1) {

          if (request.url.endsWith('/refresh_token')) {
            //alert("Vous n'êtes pas connecté. Retour à l'accueil.")
            if (regExps.length == 0) {
              setTimeout(() => {
                this.router.navigate(['connexion'])
                this.authService.signOut()
              }, 1000); //... after one seconde to let user see the message
            }
          } else {
            if (this.authService.refreshToken !== null) {
              if (!this.isRefreshingToken) {
                this.isRefreshingToken = true;
                // @ts-ignore
                return this.authService.newToken().pipe(switchMap((token: any) => {
                    if (token) {
                      this.tokenSubject.next(token.token);
                      if (token.token != null) {
                        return next.handle(request.clone({setHeaders: {Authorization: 'Bearer ' + token.token}}));
                      } else {
                        return next.handle(request.clone());
                      }
                    }
                    this.authService.signOut();
                  }),
                  catchError(error => {
                    if (regExps.length == 0) {
                      // console.log("DIAG REG EXP", regExps.length, this.router.url);
                      setTimeout(() => {
                        this.router.navigate(['connexion'])
                        this.authService.signOut()
                      }, 1000);
                    }
                    throwError(error);
                    return next.handle(request.clone());
                  }),
                  finalize(() => {
                    this.isRefreshingToken = false;
                  }));
              } else {
                return this.tokenSubject.pipe(
                  switchMap(token => {
                    if (token) {
                      return next.handle(request.clone({setHeaders: {Authorization: 'Bearer ' + token}}));
                    }
                    return next.handle(request.clone());
                  }));
              }
            }
          }

        }
        let error;
        if (typeof err === 'string') {
          error = err;
        } else {
          error = err?.error?.message || err?.statusText;
        }
        throwError(error);
      }
    ))
      ;
  }
}


